<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    ref="content"
    class="content-body"
    v-html="html"
  />
</template>

<script lang="ts" setup>
import { useContentViewer } from '~/domains/content/composables/useContentViewer'

interface ContentBodyProps {
  html: string
}

const props = defineProps<ContentBodyProps>()

const content = ref()

const { openContentBySlug } = useContentViewer()

const handleClicks = () => {
  if (!content.value) {
    console.log('Content not found')
    return
  }

  const links = Array.from(content.value.querySelectorAll('a'))

  links.forEach((link: any) => {
    const href = link.href as string

    const isCoredContent = href.includes('coredacao') && href.includes('conteudo')

    const isInternal = isCoredContent
      || href.includes('/temas-de-redacao/')
      || href.includes('/conteudo/')

    if (!isInternal) {
      link.target = '_blank'
    }

    link.addEventListener('click', (e: any) => {
      if (isInternal) {
        e.preventDefault()

        const slug = href
          .split('/')
          .filter(p => !!p)
          .pop()

        if (slug) openContentBySlug(slug)
      }
    })
  })
}

onMounted(() => {
  nextTick(() => {
    handleClicks()
  })
})

watch(() => props.html, () => {
  nextTick(() => {
    handleClicks()
  })
})
</script>

<style lang="sass">
.content-body
  box-sizing: border-box
  overflow-wrap: break-word
  br
    @apply w-full block
  h2
    margin: .5em 0 .2em
    font-size: 28px
    padding-top: 28px
    padding-bottom: 12px
    color: #090a0b
    line-height: 1.1
    font-weight: 700
  h3
    color: #090a0b
    font-size: 22px
    line-height: 1.1
    padding-top: 12px
    padding-bottom: 12px
  h4
    font-weight: 600
    font-size: 20px
  p, center, i, li, ul, blockquote
    font-size: 18px
    color: #192124
    margin-bottom: 1.6em
  ul
    margin-bottom: 12px
  .kg-embed-card, p
    position: relative
    iframe
      max-width: 100vw !important
      aspect-ratio: 16 / 9
      width: 100% !important
      height: 100% !important
  a
    font-size: 18px
    img
      display: none
  .kg-card
    display: flex
    flex-direction: column
    align-items: center
  strong
    @apply font-semibold
  figure, img, a, p, strong, em, center, i
    box-sizing: border-box
    width: 100%
    max-width: 100% !important
  figure, img, a, p, strong, em, center, i
    margin-bottom: 1.2em
  blockquote
    background: rgba(0,184,122,0.03)
    border-left: 4px solid #00B87A
    margin: 0.5em 0
    padding: 0.9em 14px
    border-radius: 5px
    margin-bottom: 2em
    white-space: pre-line
  blockquote p:first-child
    margin-top: 0

  blockquote:before
    color: #00B87A
    font-size: 4em
    line-height: 0.1em
    margin-right: 0.25em
    vertical-align: -0.4em

  blockquote p
    display: inline

  .instagram-media
    margin: 0 auto

@media (max-width: 960px)
  .content-body
    max-width: 100%
    overflow-wrap: break-word
    word-wrap: break-word

    -ms-word-break: break-all
    word-break: break-all
    word-break: break-word

    max-width: 100% !important
    p, li, a, blockquote
      font-size: 16px
    li
      margin-bottom: 5px
    h2
      font-size: 25px
      line-height: 28px
      padding-top: 14px
      padding-bottom: 8px
    h3
      font-size: 21px
      line-height: 24px
    h4
      font-size: 20px
</style>
