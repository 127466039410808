import { queryOptions } from '@tanstack/vue-query'
import type { ArticleOverview } from '../types'
import { useApi } from '~/domains/global/composables/useApi'

const FREE_THEME = {
  id: 'tema-livre',
  title: 'Tema Livre',
  slug: 'tema-livre',
  description: 'Escolha um tema livre para sua redação.',
  customExcerpt: 'Escreva sobre o que quiser.',
  featureImage:
    'https://conteudo.coredacao.com/content/images/2023/06/19468018.jpg',
}

export const getThemesQuery = () => {
  return queryOptions({
    queryKey: ['contentByTag', 'temas-de-redacao'],
    queryFn: async () => {
      const api = useApi()
      const data = await api<ArticleOverview[]>('/content/articlesByTag/temas-de-redacao', {
        method: 'GET',
      })

      // Add free theme to the list
      data.unshift(FREE_THEME)

      return data.slice(0, -1)
    },
    gcTime: 1000 * 60 * 60,
    staleTime: 1000 * 60 * 60,
  })
}
